
































































import ContentCard from "@/components/ContentCard.vue";
import {
  computed,
  defineComponent,
  provide,
  reactive,
} from "@vue/composition-api";
import { ref, watch } from "@vue/composition-api";
import {
  useGetUsersQuery,
  UserFilterType,
  UserFiltersInput,
  UserSortInfo,
  UserField,
  OrderDirection,
  PageParamsGraphType,
} from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import Table from "@/components/Table.vue";
import { TableField } from "@/types/TableField";
import useNavigateToUser from "@/composables/navigation/useNavigateToUser";
import useNavigateToTestUser from "@/composables/navigation/useNavigateToTestUser";
import { WithFilter } from "@/composables/useFilter";
import useProfile from "@/composables/useProfile";
import { useFromNowDateFormatted } from "@/composables/useDateFormat";
import { useNumberFormat } from "@/composables/useNumberFormat";
import { ParentQueryKey } from "@/views/Users/symbols";

export default defineComponent({
  components: {
    ContentCard,
    Table,
  },
  setup(_, { root }) {
    const { isCentralAdmin, isLocalAdmin, isAppAdmin } = useProfile();
    const firstLoading = ref(true);
    const filters = reactive<UserFiltersInput>({
      filterType: UserFilterType.TestUsersOnly,
      email: "",
      name: "",
      organizationName: "",
      isContainTextSearch: true,
    });

    const sortInfo = reactive<UserSortInfo>({
      direction: OrderDirection.Asc,
      field: UserField.Name,
    });

    const pageParams = reactive<PageParamsGraphType>({
      pageSize: 50,
      currentPage: 1,
    });

    const { result, loading, error, document, variables } = useGetUsersQuery(
      () => ({
        filters: filters,
        sortInfo: sortInfo,
        pageParams: pageParams,
      })
    );

    provide(ParentQueryKey, {
      query: document.value,
      variables: variables.value,
    });

    watch(
      () => useResult(result).value,
      (newValue) => {
        if (newValue) firstLoading.value = false;
      },
      { immediate: true }
    );

    function filterFunction() {
      return true;
    }

    function filtersChanged(newFilters: WithFilter[]) {
      newFilters.forEach((filter) => {
        if (filter.key == "fullname" && filter.filter != filters.name)
          filters.name = filter.filter;
        if (filter.key == "email" && filter.filter != filters.email)
          filters.email = filter.filter;
        if (
          filter.key == "organization.name" &&
          filter.filter != filters.organizationName
        )
          filters.organizationName = filter.filter;

        pageParams.currentPage = 1;
      });
    }

    function sortByChanged(field: string) {
      if (field == "fullname") sortInfo.field = UserField.Name;
      if (field == "email") sortInfo.field = UserField.Email;
      if (field == "organization.name")
        sortInfo.field = UserField.OrganizationName;
      if (field == "lastLogin") sortInfo.field = UserField.LastSeen;
    }

    function sortDescChanged(desc: boolean) {
      sortInfo.direction = desc ? OrderDirection.Desc : OrderDirection.Asc;
    }

    return {
      editable: computed(
        () => isCentralAdmin.value || isLocalAdmin.value || isAppAdmin.value
      ),
      loading,
      error,
      firstLoading,
      pageParams,
      useFromNowDateFormatted,
      totalCount: useResult(result, undefined, (data) => data.users.totalCount),
      items: useResult(result, [], (data) => data.users.nodes),
      fields: computed<TableField[]>(() => [
        {
          key: "fullname",
          label: root.$tc("users.fields.name"),
          sortable: true,
          filter: true,
        },
        {
          key: "organization.name",
          label: root.$tc("users.fields.organisation"),
          sortable: true,
          filter: true,
        },
        {
          key: "email",
          label: root.$tc("users.fields.email"),
          sortable: true,
          filter: true,
        },
        {
          key: "lastLogin",
          label: root.$tc("users.fields.lastSeen"),
          sortable: true,
          filter: false,
          width: "10%",
        },
      ]),
      filterFunction,
      filtersChanged,
      sortByChanged,
      sortDescChanged,
      useNavigateToUser,
      useNavigateToTestUser,
      useNumberFormat,
    };
  },
});
